.contact-wrapper {
  background: #fff;

  .contact {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    .main {
      width: 100%;
    }

    .info {
      color: #555;
      @media (max-width: 767px) {
        margin-top: 20px;
        justify-self: flex-start;
      }

      h1 {
        font-size: 18px;
        color: #333;
        margin-bottom: 7px;
      }
      p {
        color: #838282;
        line-height: 2;
      }
    }
  }

  .success {
    background: rgb(8, 206, 8);
    padding: 4px 9px;
    font-size: 13px;
    color: #fff;
  }

  .form-item {
    width: 100%;
    margin-bottom: 15px;
    // display: grid;
    // grid-template-columns: 100px 1fr;
    // align-items: center;

  

    label {
      font-weight: 700;
      color: #333;
      display: block;
      margin-bottom: 5px;
      font-size: 12px;
      span.red {
        color: red;
      }
    }

    input,
    textarea {
      padding: 10px 15px;
      border-radius: 4px;
      border: 0;
      width: 100%;
      box-sizing: border-box;
      background: #eee;
    }
  }
}
