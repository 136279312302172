@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

section {
  padding: 70px 0;
}

.wrap {
  width: 80%;
  margin: auto;
}

.max-w {
  width: 100%;
  max-width: 1080px;
  height: 100%;
  margin: 0 auto;
  // background: teal;
  // background: rbga(255, 255, 255, .9);
}

// @media(max-width: 600px) {
//   .max-w {
//     padding: 0 1rem;
//   }
// }


.empty {
  height: 30px;
  width: 100%;
  // background: red;
}