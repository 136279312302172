@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
html{scroll-behavior:smooth}body{margin:0;font-family:'Montserrat', sans-serif;overflow-x:hidden}h1,h2,h3,h4,h5,h6,p{margin:0}section{padding:70px 0}.wrap{width:80%;margin:auto}.max-w{width:100%;max-width:1080px;height:100%;margin:0 auto}.empty{height:30px;width:100%}

.hero-section{background-image:linear-gradient(rgba(255,255,255,0.4), rgba(0,0,0,0)),url("/cybersecurity.jpg");height:100vh;background-repeat:no-repeat;background-size:cover;background-attachment:fixed}@media (max-width: 767px){.hero-section{height:80vh}}.hero-section .hero-content{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}@media (max-width: 500px){.hero-section .hero-content{-webkit-justify-content:center;justify-content:center}}.hero-section header{background:rgba(0,0,0,0.6);padding:2rem 1rem;width:90%}.hero-section header h1{color:#fff;margin:0;font-size:70px;margin-bottom:10px}@media (max-width: 767px){.hero-section header h1{font-size:55px}}.hero-section header h1 span.blue{color:#34A49D}.hero-section header p{color:#eee;margin:8px 0;font-size:22px}@media (max-width: 767px){.hero-section header p{font-size:20px}}

.social-button ul{list-style:none;padding:0}.social-button li{display:inline-block;padding:5px 10px;font-size:24px;color:#fff}.social-button li a{color:#fff;transition:0.2s}.social-button li:nth-child(1) a:hover{color:#4267B2}.social-button li:nth-child(2) a:hover{color:#1DA1F2}.social-button li:nth-child(3) a:hover{color:#0e76a8}

.about-wrapper{background:#fff}.about-me{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}@media (max-width: 767px){.about-me{-webkit-flex-direction:column;flex-direction:column}}.about-me .img-holder{width:250px;height:250px}@media (max-width: 767px){.about-me .img-holder{width:100%;height:250px}}.about-me .img-holder img{border-radius:50%;object-fit:cover;height:100%;width:100%}@media (max-width: 767px){.about-me .img-holder img{border-radius:0}}.about-me aside{width:66%}@media (max-width: 767px){.about-me aside{width:100%;margin-top:1rem}}.about-me aside p#resume{color:#333;padding:1rem 1.2rem 0 0;letter-spacing:0.5px;line-height:2}header.title{margin-bottom:15px}header.title h1{color:#000;font-size:34px;position:relative}header.title h1::after{content:"";position:absolute;bottom:-5px;left:0;width:100px;background:#34A49D;border-radius:4px;height:4px}.download-resume{margin-top:1rem}.download-resume a{text-decoration:none}.download-resume .btn-download{padding:8px 20px;background:#34A49D;border-radius:4px;color:#fff;display:-webkit-inline-flex;display:inline-flex;-webkit-align-items:center;align-items:center;transition:0.3s;cursor:pointer;border:0;border:1px solid #34A49D}.download-resume .btn-download p{margin-left:10px;font-weight:700;font-size:15px;text-decoration:none}.download-resume .btn-download:hover{background:#eee;color:#000;border:1px solid #000}

.education-wrapper{background:#EBEEF2}.education-wrapper main{width:100%}.education{display:grid;grid-template-columns:180px 250px 1fr}@media (max-width: 1200px){.education{grid-template-columns:0px 180px 1fr}}@media (max-width: 767px){.education{grid-template-columns:auto;justify-items:center;grid-gap:20px}}.education .education-item{color:#fff;margin-bottom:40px}.education .education-item h1{font-size:28px;margin-bottom:5px;color:#34A49D}.education .education-item h4{font-size:18px;font-weight:500;color:#333}.education .education-item h4 span{margin-left:10px;font-size:16px}.education .education-item p{margin-top:7px;color:#838c95;font-style:14px}.education .work-item{color:#EBD61F;margin-bottom:40px}.education .work-item h1{font-size:28px;margin-bottom:5px;color:#34A49D}.education .work-item h4{font-size:18px;font-weight:500;color:#777}.education .work-item h4 span{margin-left:10px;font-size:16px}.education .work-item p{margin-top:7px;color:#333;font-style:14px}

.skill-item{margin-bottom:30px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:flex-start;justify-content:flex-start}.skill-item h1{color:#333;font-size:18px;text-transform:uppercase;margin-bottom:10px;width:120px}.skill-item .bar{position:relative;background:#ddd;height:20px;width:400px;border-radius:25px;box-shadow:8px 10px 30px -17px rgba(0,0,0,0.9);-webkit-box-shadow:8px 10px 30px -17px rgba(0,0,0,0.9);-moz-box-shadow:8px 10px 30px -17px rgba(0,0,0,0.9)}@media (max-width: 767px){.skill-item .bar{width:100%}}.skill-item .bar .overlay{position:absolute;border-radius:25px;top:0;bottom:0;left:0;background:#ebd61f}.skill-item .bar .text{position:absolute;z-index:100;top:3px;font-weight:700;left:5px}.skills-list li{background:#fff;padding:5px 6px;margin:2px 0}

footer{background:#34A49D;color:#fff;padding:40px 0;text-align:center}footer ul{list-style:none;margin:0;padding:0}footer ul li{display:inline-block;padding:0 10px;font-size:24px;color:#fff;transition:all 0.3s;cursor:pointer}footer ul li:hover{color:#ddd}footer p{font-size:14px;font-weight:400;color:#eee;margin-top:10px}footer p a{color:#EBD61F;text-decoration:none}footer p a:hover{color:rgba(235,214,31,0.7)}

.contact-wrapper{background:#fff}.contact-wrapper .contact{display:grid;grid-template-columns:1fr 1fr 1fr;justify-items:center}@media (max-width: 767px){.contact-wrapper .contact{grid-template-columns:1fr;grid-gap:20px}}.contact-wrapper .contact .main{width:100%}.contact-wrapper .contact .info{color:#555}@media (max-width: 767px){.contact-wrapper .contact .info{margin-top:20px;justify-self:flex-start}}.contact-wrapper .contact .info h1{font-size:18px;color:#333;margin-bottom:7px}.contact-wrapper .contact .info p{color:#838282;line-height:2}.contact-wrapper .success{background:#08ce08;padding:4px 9px;font-size:13px;color:#fff}.contact-wrapper .form-item{width:100%;margin-bottom:15px}.contact-wrapper .form-item label{font-weight:700;color:#333;display:block;margin-bottom:5px;font-size:12px}.contact-wrapper .form-item label span.red{color:red}.contact-wrapper .form-item input,.contact-wrapper .form-item textarea{padding:10px 15px;border-radius:4px;border:0;width:100%;box-sizing:border-box;background:#eee}


.header {
    box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    -webkit-box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    -moz-box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    position: fixed;
    top: 0;
    background-color: #fff;
    height: 60px;
    width: 100%;
    z-index: 1000;

}
.navbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    /* padding: 0.3rem 0; */
   
}

.nav-logo {
    text-decoration: none;
    color: #34A49D;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}
.nav-menu {
    display: -webkit-flex;
    display: flex;
    width: 500px;
    /* background: teal; */
    -webkit-justify-content: space-between;
            justify-content: space-between;
    list-style: none;
    text-decoration: none;
    -webkit-align-items: center;
            align-items: center;
}

/* .nav-item {
    background: teal;
    padding: 5px 1rem;
    color: #fff;
} */

.nav-item a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 5px 1rem;
    border-radius: 4px;
}

.nav-item a:hover {
    background: #34A49D;
    color: #fff;
    padding: 5px 1rem;
}

/* .nav-item {
    margin-left: 5rem;
} */

.nav-link{
    font-size: 1.6rem;
    font-weight: 400;
    color: #475569;
}

.nav-link:hover{
    color: #482ff7;
}

.nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
}

@media only screen and (max-width: 768px) {
    .nav-menu {
        position: fixed;
        /* left: -100%; */
        top: -100%;
        /* top: 5rem; */
        right: 0;
        -webkit-flex-direction: column;
                flex-direction: column;
        /* align-items: center;
        justify-content: center ; */
        background-color: rgba(255, 255, 255, .9);
        width: 150px;
        border-radius: 4px;
        /* text-align: center; */
        transition: 0.3s;
        text-align: center;
        box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    -webkit-box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    -moz-box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    }

    .nav-menu.active {
        /* left: 0; */
        top: 48px;
        right: 5px;
       
    }

    .nav-item {
        margin: 1rem 2rem 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, .3);
        width: 100%;
        padding-bottom: 8px;
        /* background: red; */
    }

 

    .hamburger {
        display: block;
        cursor: pointer;
        margin-right: 2rem;
    }
  
  
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        -webkit-transform: translateY(8px) rotate(45deg);
                transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        -webkit-transform: translateY(-8px) rotate(-45deg);
                transform: translateY(-8px) rotate(-45deg);
    }


}

