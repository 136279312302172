
.header {
    box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    -webkit-box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    -moz-box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    position: fixed;
    top: 0;
    background-color: #fff;
    height: 60px;
    width: 100%;
    z-index: 1000;

}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0.3rem 0; */
   
}

.nav-logo {
    text-decoration: none;
    color: #34A49D;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}
.nav-menu {
    display: flex;
    width: 500px;
    /* background: teal; */
    justify-content: space-between;
    list-style: none;
    text-decoration: none;
    align-items: center;
}

/* .nav-item {
    background: teal;
    padding: 5px 1rem;
    color: #fff;
} */

.nav-item a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 5px 1rem;
    border-radius: 4px;
}

.nav-item a:hover {
    background: #34A49D;
    color: #fff;
    padding: 5px 1rem;
}

/* .nav-item {
    margin-left: 5rem;
} */

.nav-link{
    font-size: 1.6rem;
    font-weight: 400;
    color: #475569;
}

.nav-link:hover{
    color: #482ff7;
}

.nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
}

@media only screen and (max-width: 768px) {
    .nav-menu {
        position: fixed;
        /* left: -100%; */
        top: -100%;
        /* top: 5rem; */
        right: 0;
        flex-direction: column;
        /* align-items: center;
        justify-content: center ; */
        background-color: rgba(255, 255, 255, .9);
        width: 150px;
        border-radius: 4px;
        /* text-align: center; */
        transition: 0.3s;
        text-align: center;
        box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    -webkit-box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    -moz-box-shadow: 8px 10px 30px -17px rgba(0,0,0,0.9);
    }

    .nav-menu.active {
        /* left: 0; */
        top: 48px;
        right: 5px;
       
    }

    .nav-item {
        margin: 1rem 2rem 1rem 0;
        border-bottom: 1px solid rgba(0, 0, 0, .3);
        width: 100%;
        padding-bottom: 8px;
        /* background: red; */
    }

 

    .hamburger {
        display: block;
        cursor: pointer;
        margin-right: 2rem;
    }
  
  
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }


}
